import React, {MouseEvent, useState} from 'react'
import faq from '../../../../data/faq.json'
import {Accordion} from '../../common'
import styles from './faq-list.module.css'

export const FaqList = () => {
  const [opened, setOpened] = useState(``)

  const handleSetOpened = (event: MouseEvent<HTMLDivElement>) => {
    const item = event?.currentTarget?.dataset?.item ?? ``
    setOpened(current => (item === current ? `` : item))
  }

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <Accordion
          data={faq}
          opened={opened}
          handleSetOpened={handleSetOpened}
        />
      </div>
    </section>
  )
}
