import React from 'react'
import {DefaultLayout, Footer, Header} from '../../components/common'
import {FaqList} from '../../components/faq'
import styles from './index.module.css'

const logoColors = {
  primary: `#fefce1`,
  secondary: `#2c72b6`,
  shadow: `transparent`,
}

const Index = () => (
  <DefaultLayout>
    <div className={styles.page}>
      <Header
        logoColors={logoColors}
        title="Frequently Asked Questions"
        headerClass={styles.headerSection}
      />
      <FaqList />
      <Footer />
    </div>
  </DefaultLayout>
)

Index.displayName = `FaqPage`

export default Index
